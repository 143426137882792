import styled from "@emotion/styled";
import { GrButton, palette } from "@jnpmedi/grebob";

export const StyledPopupWrapper = styled("div")({
  width: "600px",
  height: "570px",
  backgroundColor: palette.white,
  padding: "1.5rem",
  borderRadius: "0.5rem",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 30px 0px",
});

export const StyledTextWrapper = styled("span")(({ fontSize, fontWeight }: { fontSize?: string; fontWeight?: number }) => ({
  fontSize: fontSize ?? "0.8rem",
  fontWeight: fontWeight ?? 500,
}));

interface StyledPopupInfoWrapperProps {
  height?: string;
}

export const StyledPopupInfoWrapper = styled("div")(({ height }: StyledPopupInfoWrapperProps) => ({
  height: height ? height : "auto",
  padding: "1.25rem",
  borderRadius: "0.375rem",
  backgroundColor: palette.bg020,
  marginBottom: "1.25rem",
  flexGrow: 0,
}));

export const StyledCloseButton = styled(GrButton)({
  width: "80px",
  height: "32px",
});
