import { MavenProductService, MavenService } from "./common.types";

export function readableMavenService(mavenService: MavenService): string {
  const mavenServiceMap: { [key in MavenService]?: string } = {
    [MavenService.MavenAuth]: "Maven Auth",
    [MavenService.MavenCDMS]: "Maven CDMS",
  };

  return mavenServiceMap[mavenService] || "Unknown";
}

export const readableMavenProductService = (type: MavenProductService): string => {
  switch (type) {
    case MavenService.MavenCDMS:
      return "CDMS";

    case MavenService.MavenDocs:
      return "Docs";

    case MavenService.MavenSafety:
      return "Safety";

    case MavenService.MavenAuth:
      return "Auth";

    case MavenService.MavenBuilder:
      return "Builder";

    case MavenService.MavenVDR:
      return "VDR";

    case MavenService.MavenTMF:
      return "TMF";

    default:
      return "(Unknown)";
  }
};

export const isMavenProductService = (service: MavenService | string): service is MavenProductService => {
  switch (service) {
    case MavenService.MavenCDMS:
    case MavenService.MavenDocs:
    case MavenService.MavenSafety:
    case MavenService.MavenAuth:
    case MavenService.MavenBuilder:
    case MavenService.MavenVDR:
    case MavenService.MavenTMF:
      return true;
    default:
      return false;
  }
};
