import { GetReleaseHistoryRequest, GetReleaseHistoryResponse } from "./ReleaseHistory.internal.dto";
import { ReleaseHistoryInternalUrl } from "./ReleaseHistory.internal.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class ReleaseHistoryFlax {
  public static getReleaseHistories(data: FxRequestData<GetReleaseHistoryRequest>): FxApiRequestData<GetReleaseHistoryResponse> {
    return {
      ...restReq(process.env.REACT_APP_DOCS_BE_URL, ReleaseHistoryInternalUrl.getReleaseHistories, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
