import styled from "@emotion/styled";
import { palette } from "@jnpmedi/grebob";
import { GrStack } from "@jnpmedi/grebob/dist/components/layout/GrStack";
import { Markdown } from "./markdown/Markdown";
import { DOCS_POPUP_DATE_FORMAT, DOCS_RELEASE_POPUP_KEY } from "./Popup";
import { StyledCloseButton, StyledPopupInfoWrapper, StyledPopupWrapper, StyledTextWrapper } from "./Popup.style";

import { MvnMessage } from "@maven-msg/lib/Message";
import { isAdminReleaseLang } from "@maven-rest/admin/Release.conv";
import { AdminReleaseWithContent } from "@maven-rest/admin/Release.dto";
import { readableMavenProductService } from "@maven-rest/common/common.conv";
import { LoLang, LoString } from "@maven-rest/common/common.types";
import { LOCALE_LANG_KEY, setCookie } from "@maven-rest/states";
import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import dayjs from "dayjs";

interface ReleasePopupProps {
  release: AdminReleaseWithContent;
  onClose: () => void;
}

export function ReleasePopup(props: ReleasePopupProps) {
  const { release, onClose } = props;
  const { service, version, updatedAt, content } = release;

  const localStorageLanguage = localStorage.getItem(LOCALE_LANG_KEY) ?? "";
  const lang = isAdminReleaseLang(localStorageLanguage) ? localStorageLanguage : LoLang.KO;

  const handleReleasePopupClose = () => {
    setCookie(DOCS_RELEASE_POPUP_KEY, version, dayjs(getCurrentDate()).add(1, "day").toDate());

    onClose();
  };

  return (
    <StyledPopupWrapper>
      <GrStack height="calc(100% - 40px)" flexDirection="column">
        <StyledTextWrapper fontSize="1.25rem" fontWeight={700} style={{ marginBottom: "1.5rem" }}>
          Version & Release
        </StyledTextWrapper>
        <StyledContentWrapper>
          <StyledPopupInfoWrapper height="92px">
            <StyledTextWrapper fontSize="1rem" fontWeight={700}>
              {readableMavenProductService(service)}
            </StyledTextWrapper>
            <StyledReleaseTitleWrapper>
              <StyledLabelTextWrapper width="6.5rem">Current Version</StyledLabelTextWrapper>
              <StyledTextWrapper>{version}</StyledTextWrapper>
              <StyledLabelTextWrapper style={{ marginLeft: "2rem" }}>Updated</StyledLabelTextWrapper>
              <StyledTextWrapper>{dayjs(updatedAt).format(DOCS_POPUP_DATE_FORMAT)}</StyledTextWrapper>
            </StyledReleaseTitleWrapper>
          </StyledPopupInfoWrapper>
          <Markdown text={content[lang.toLowerCase() as keyof LoString] ?? Object.values(content).pop() ?? ""} />
        </StyledContentWrapper>
      </GrStack>
      <GrStack height="32px" margin="8px 0 0 0" justifyContent="flex-end" alignItems="center">
        <StyledCloseButton color="grey" onClick={handleReleasePopupClose}>
          {MvnMessage({ en: "Close", ko: "닫기" })}
        </StyledCloseButton>
      </GrStack>
    </StyledPopupWrapper>
  );
}

const StyledReleaseTitleWrapper = styled.p`
  height: "17.5px";
  margin: "16px 0 0 0";
`;

const StyledLabelTextWrapper = styled(StyledTextWrapper)(({ width }: { width?: string }) => ({
  width: width ?? "5rem",
  color: palette.grey060,
  marginRight: "0.5rem",
}));

const StyledContentWrapper = styled("div")({
  display: "flex",
  height: "calc(100% - 3.15rem)",
  flexDirection: "column",
  overflow: "auto",
});
