import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRequest } from "@coralblack/flax";
import { NoticePopup } from "./NoticePopup";
import { ReleasePopup } from "./ReleasePopup";
import { ReleaseHistoryFlax } from "@maven-rest/docs/ReleaseHistory.internal.flax";
import { getCookie } from "@maven-rest/states";
import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import dayjs from "dayjs";

export const DOCS_NOTICE_POPUP_KEY = (id: bigint) => `docs:notice:popup:key:${id}`;
export const DOCS_RELEASE_POPUP_KEY = "docs:release:popup:key";
export const DOCS_POPUP_DATE_FORMAT = "YYYY/MM/DD";

export function Popup() {
  const [showNoticePopups, setShowNoticePopups] = useState<Map<bigint, boolean>>(new Map());
  const [showReleasePopup, setShowReleasePopup] = useState<boolean>(false);

  const handleNoticePopupClose = (noticeId: bigint) => {
    setShowNoticePopups((prev) => {
      const newShowNoticePopups = new Map(prev);

      newShowNoticePopups.set(noticeId, false);

      return newShowNoticePopups;
    });
  };

  const handleReleasePopupClose = () => {
    setShowReleasePopup(false);
  };

  const {
    request: getPopupsRequest,
    response: { busy: getPopupsResponseBusy, response: getPopupsResponse },
  } = useRequest(ReleaseHistoryFlax.getReleaseHistories({}), {
    success: ({ notices, release }) => {
      if (notices?.length) {
        const showNoticePopupMap: Map<bigint, boolean> = new Map();

        notices.forEach(({ id }) => {
          const existDate = getCookie(DOCS_NOTICE_POPUP_KEY(id));

          showNoticePopupMap.set(id, existDate ? !dayjs(getCurrentDate()).isSame(existDate, "day") : true);
        });

        setShowNoticePopups(showNoticePopupMap);
      }

      if (release) {
        const existVersion = getCookie(DOCS_RELEASE_POPUP_KEY) ?? "";

        setShowReleasePopup(release.version !== existVersion);
      }
    },
  });

  useEffect(() => {
    getPopupsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.style.overflow =
      Array.from(showNoticePopups.values()).some((isShow) => isShow) || showReleasePopup ? "hidden" : "visible";
  }, [showNoticePopups, showReleasePopup]);

  return (
    <>
      {!getPopupsResponseBusy && (Array.from(showNoticePopups.values()).some((isShow) => isShow) || showReleasePopup) && (
        <StyledBackgroundWrapper>
          {getPopupsResponse?.notices?.length &&
            getPopupsResponse.notices.map(
              (notice) =>
                !!showNoticePopups.get(notice.id) && <NoticePopup key={`${notice.id}`} notice={notice} onClose={handleNoticePopupClose} />
            )}
          {getPopupsResponse?.release && showReleasePopup && (
            <ReleasePopup release={getPopupsResponse.release} onClose={handleReleasePopupClose} />
          )}
        </StyledBackgroundWrapper>
      )}
    </>
  );
}

const StyledBackgroundWrapper = styled("div")({
  zIndex: 100,
  position: "absolute",
  top: "0px",
  left: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "2rem",
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
});
