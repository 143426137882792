import { MvnCodeFE } from "../const";

export const BILLING_NOTIFICATION_MAIL_LANG: {
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_EXPIRED_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_CONTENT: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_EXPIRE_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_EXTENDING_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_EXTENDED_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_UPGRADE_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_SUCC_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_PURCHASE_SUCC_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_CONTENT_BTN_LINK: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_EXPIRE_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_EXPIRED_TITLE: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_CONTENT_001: MvnCodeFE;
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_CONTENT_002: MvnCodeFE;
} = {
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_EXPIRED_TITLE: {
    ko: "Free Trial이 만료됐습니다",
    en: "Free Trial has been expired",
  },
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_CONTENT: {
    ko: "정식 Plan을 구매하여 업무의 효율성을 경험해보세요.",
    en: "Subscribe official plans to enhance your performance.",
  },
  BILLING_NOTIFICATION_MAIL_FREE_TRIAL_SUBSCRIPTION_EXPIRE_TITLE: {
    ko: "Free Trial이 곧 만료됩니다",
    en: "Free Trial will be expired soon",
  },
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_EXTENDING_TITLE: {
    ko: "정기 구독이 결제될 예정입니다",
    en: "Your subscription plan will be paid soon",
  },
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_EXTENDED_TITLE: {
    ko: "정기 구독 결제가 완료됐습니다",
    en: "Subscription plan updated successfully",
  },
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_UPGRADE_TITLE: {
    ko: "구독 변경이 완료되었습니다",
    en: "Subscription plan has been updated",
  },
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_SUCC_TITLE: {
    ko: "구독 신청이 성공적으로 완료되었습니다",
    en: "Subscription request completed successfully",
  },
  BILLING_NOTIFICATION_MAIL_PURCHASE_SUCC_TITLE: {
    ko: "구매가 성공적으로 완료되었습니다",
    en: "Purchase completed successfully",
  },
  BILLING_NOTIFICATION_MAIL_SUBSCRIPTION_CONTENT_BTN_LINK: {
    ko: "{product} Plan 보기",
    en: "Check {product} plans",
  },
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_EXPIRE_TITLE: {
    ko: "{plan}이 곧 만료됩니다",
    en: "{plan} will be expired soon",
  },
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_EXPIRED_TITLE: {
    ko: "{plan}이 만료됐습니다",
    en: "{plan} has been expired",
  },
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_CONTENT_001: {
    ko: "{product} Plan을 연장하기 위해 제품 담당자에게 연락주시기 바랍니다.",
    en: "Please contact your manager, if you want to renew {product} plan.",
  },
  BILLING_NOTIFICATION_MAIL_NONRENEWABLE_PLAN_SUBSCRIPTION_CONTENT_002: {
    ko: "또는 아래 {product} Plan을 통하여 제품을 계속 사용할 수 있습니다.",
    en: "You can also subscribe other {product} plans by link below.",
  },
};
